*:not(.login-wrapper) {
  .password-group {
    position: relative;
    .show-pass {
      position: absolute;
      padding: 0 3px;
      right: 38px;
      top: 15px;
      border: 0;
      background: transparent;
      outline: none;
      z-index: 99;
      &:hover,
      &:active {
        background: transparent;
        opacity: .6;
      }
    }
  }
}

.login-wrapper {
  @include flex-container(column, flex-start, center);
  align-items: center;
  height: auto !important;
  padding-top: 35px;
  .container {
    max-width: none;
    padding: 0 80px;
  }
  h4 {
    &.form-title {
      text-align: center;
      font-size: 28px;
      margin-bottom: 40px;
      font-weight: 700;
      letter-spacing: 0.25px;
      color: $label_color;
    }
  }
  a.reset_pass {
    color: $link-color-gray;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
  .form-wrapper-inner {
    min-width: 370px;
    width: 100%;

    .alert {
      margin-bottom: 0;
    }

    .form {
      display: block;
      max-width: 370px;
      height: auto;
      max-height: 570px;
      width: 100%;
      padding: 32px 45px 32px;
      background: $form-bg;
      margin: 0 auto;
      border-radius: 3px;
      box-shadow: $basic-box-shadow;

      .form-group {
        margin-bottom: 0;

        input[type='password'] {
          font-size: 18px;
        }

        input[type='checkbox'] {
          display: none;

          &+label {
            font-size: 12px;
            line-height: 16px;
            position: relative;
            padding-left: 36px;
            text-transform: none;
            white-space: normal;

            a {
              text-decoration: underline;
            }

            &:hover {
              cursor: pointer;
            }

            &:before {
              content: '';
              position: absolute;
              width: 24px;
              height: 24px;
              left: 0;
              bottom: 50%;
              margin-bottom: -12px;
              border-radius: 2px;
              background: transparent;
              z-index: 1;
              border: 2px solid $border-color-basic;
            }

            &:after {
              content: '';
              width: 15px;
              height: 12px;
              background: url("/assets/icons/checkbox-white-second.svg");
              position: absolute;
              left: 5px;
              top: 50%;
              margin-top: -6px;
              z-index: 2;
              opacity: 0;
            }
          }

          &:checked {
            &+label {
              &:after {
                opacity: 1;
              }

              &:before {
                border-color: $checkbox-color;
                background: $checkbox-color;
              }
            }
          }
        }

        .checkbox-link {
          color: $text-def;
          font-weight: 500;
        }

        &+.form-group {
          margin-top: 32px;

          &.restore-password {
            margin-top: 12px;
          }
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &.register-form {
        padding: 32px 45px 24px;
        max-height: none;

        a {
          color: $link-color;
        }

        h4.form-title {
          line-height: 40px;
          margin-bottom: 48px;
        }

        .captcha-wrapper {
          margin-top: 18px !important;
        }

        .form-group.controls {
          margin-top: 25px;
        }
      }
    }
  }
  .password-group {
    position: relative;

    input[type='password'],
    input[type='text'] {
      padding-right: 35px !important;
      &.is-invalid,
      &.is-invalid:focus {
        padding-right: 35px !important;
      }
    }

    .show-pass {
      position: absolute;
      padding: 0 3px;
      right: 15px;
      top: 39px;
      border: 0;
      background: transparent;
      outline: none;
      width: 22px;

      svg {
        width: 100%;
        height: 100%;
      }

      &:hover,
      &:active {
        background: transparent;
        opacity: 0.6;
      }
    }
  }
  .restore-password {
    margin-top: -12px;
    text-align: right;
  }
  .info-holder {
    right: 42px;
    transform: translateY(0);
  }
  .instructions-text {
    display: block;
    margin-bottom: 16px;
    max-width: 545px;
    width: 100%;
    margin: 0 auto;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    .invalid-feedback {
      text-align: start;
    }

    h4.title {
      font-size: 28px;
      line-height: 34px;
      letter-spacing: 0.25px;
      font-weight: 700;
    }

    p {
      line-height: 26px;
    }
  }
  .icon-container {
    display: flex;
    width: 136px;
    height: 136px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 36px;

    &.error {
      background: transparent;
      border: 0;
    }
  }
  .go-back-button-wrapper {
    max-width: 280px;
    margin: 60px auto 0;
  }
  .counter-wrapper {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.25px;
    font-weight: 700;
  }
  .phone-code {
    @include flex-container(row, center, center);
    .form-control {
      text-align: center;
      max-width: 40px;
      border: 1px solid transparent !important;
      border: 2px solid $black !important;
      border-radius: 4px;
      padding: 0 6px;
      font-size: 34px;
      line-height: 1.2;
      height: 58px !important;
      color: $text-def;
      margin: 0 8px;
      background: transparent;

      &:focus {
        background: $form-bg;
        border-color: $focused-input!important;
        border-width: 2px;
      }

      &.is-invalid:focus {
        padding: 0 6px;
      }
    }
  }
}

@media (max-width: 767.5px) {
  *:not(.login-wrapper) {
    .password-group .show-pass {
      right: 25px;
      top: 7px;
    }
  }
  .login-wrapper {
    height: auto !important;
    min-height: calc(100vh - 109px);
    background: $login-form-bg;
    .container {
      padding: 0 24px;
    }
    .form-wrapper-inner {
      min-width: inherit;
      .form {
        padding: 32px;
        min-height: calc(100vh - 109px);
        border-radius: 0;
        max-height: none;
        max-width: none;
        box-shadow: none;
        &.register-form {
          padding: 32px;

          .captcha-wrapper {
            margin-top: 16px !important;
          }
          .form-group.controls {
            margin-top: 25px;
          }
        }
      }
    }
    .form-control:not(.confirm-code),
    .ng-select.ng-select-single .ng-select-container {
      border: 0 !important;
      border-bottom: 2px solid $border-color-basic !important;
      height: 32px !important;
      padding-left: 0;
      border-radius: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      position: relative;

      &:focus {
        background: transparent;
        border-bottom-color: $focused-input !important;
      }

      .ng-value-container .ng-input {
        top: 0;
      }
    }
    .was-validated .form-control:invalid:focus,
    .form-control.is-invalid:focus,
    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      border-bottom: 2px solid $error-input !important;
    }
    .password-group {
      .show-pass {
        right: 0;
        top: auto;
        bottom: 50%;
        width: 22px;
        margin-bottom: -16px;
      }

      &::after {
        left: 0;
        right: 0;
      }
      .info-holder {
        right: 32px;
        margin-top: -3px;
      }
    }
    .password-stength-wrapper {
      bottom: auto;
      top: 54px;
    }
    .instructions-text {
      padding-left: 24px;
      padding-right: 24px;
    }

    .body-overflow-mobile {
      overflow: hidden;
      height: 100vh;
      width: 100%;
      position: fixed;
    }
  }
}
