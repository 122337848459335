@import 'src/scss/variables';

.body-overflow-mobile {
  overflow: scroll;
}

.navigation-mobile {
  z-index: 100;
  background: $background-light;

  padding: 0 8px;
  @include flex-container(row, flex-start, center);
  @include text-ellipsis(100%);

  &>span {
    display: block;
    @include text-ellipsis(100%);
  }

  &.change-position {
    left: 0;
    margin-top: -18px;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .show-arrow {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: -48px;
      top: -5px;
      width: 24px;
      height: 24px;
      display: block;
      background: url("/assets/icons/arrow-left.svg") no-repeat center left;
    }
  }
}

.user-passport {
  font-size: 24px;
  line-height: 32px;
  width: 100%;
  max-width: 400px;

  .passport-number-wrapper {
    span {
      padding-right: 10px;
    }

    span,
    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.modal-dialog {
  max-width: 480px;
}

@media (max-width: 1199px) {
  .container {
    max-width: 1140px !important;
  }

  .modal-open .modal.checkpoint-modal .modal-dialog,
  .modal-open .modal.checks-info .modal-dialog {
    max-width: 670px;

    .nav {
      border-bottom: 0;

      .nav-item {
        .nav-link {
          span {
            font-weight: 500;
            font-size: 20px;
            opacity: 1;
            color: $text-def;
          }

          &.active {
            span {
              color: $link-color;
            }
          }
        }
      }
    }
  }

  body {
    background: $body-bg;
  }

  .page-title {
    padding-left: 0 !important;
  }

  app-profile-edit {
    ngb-tabset {
      display: block;
    }
  }

  /* notifications */
  app-notifications {
    h4.p-left-45 {
      padding-left: 0 !important;
    }
  }

  /* notifications end */
}

@media (max-width: 1024px) {
  .nav .nav-item .nav-link span {
    font-size: 14px;
    padding: 0 12px;
  }

  .form-control:not(.confirm-code),
  .ng-select.ng-select-container {
    width: 100% !important;
    max-width: none !important;
  }

  .table-wrapper table.table {
    table-layout: fixed;
  }
}

@media (max-width: 767.5px) {
  body {
    padding-bottom: 0;
  }

  input,
  textarea,
  input[ type='password'],
  input[ type='email'],
  input[ type='text'],
  input[ type='number'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none !important;
    border-radius: 0 !important;
  }

  .language-select-wrapper {
    .ng-select {
      .ng-select-container .ng-value-container .ng-value {
        position: relative;
        top: 0;
      }

      .ng-arrow-wrapper .ng-arrow {
        margin-top: 5px;
      }
    }
  }

  .form-control:not(.confirm-code),
  .ng-select.ng-select-container {
    width: 100% !important;
    max-width: none !important;
  }

  .form-group:after {
    left: 15px;
    right: 15px;
  }

  .password-group {
    .form-control.has-info {
      padding-right: 64px;
    }

    .info-holder {
      right: 0;
    }
  }

  /* Offers mobile view */

  .offer-item-inner {
    padding: 15px !important;
    .currency {
      font-size: 20px;
      padding-left: 5px;
    }
  }

  .item-inner-element {
    min-height: 52px;
    padding: 8px 12px;
    @include flex-container(row, space-between, center);
    flex-wrap: wrap;

    .title-wrapper {
      @include flex-container(row, space-between, flex-start);
      margin-bottom: 16px;

      .icon {
        flex: 1 0 auto;
      }
    }

    .offer-description {
      font-size: 16px;
      line-height: 28px;
      word-wrap: break-word;
      overflow: hidden;
      color: $text-def;
    }

    .label {
      letter-spacing: .3px;
      font-size: 12px;
      color: $text-secondary;
    }

    .title {
      display: inline-block;
      font-size: 16px;
      line-height: 24px;
      color: $link-color;
    }

    .value {
      text-align: right;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: .3px;

      &.refund {
        font-size: 16px;
        line-height: 28px;
        color: $link-color;
      }

      &.price {
        @include flex-container(column, flex-start, flex-end);
      }

      .base-price {
        .price {
          font-size: 16px;
          line-height: 26px;
        }

        .currency {
          font-size: 16px;
          line-height: 26px;
        }
      }

      .price-ex {
        font-size: 14px;
        line-height: 28px;
        opacity: .5;
      }
    }

    &+.item-inner-element {
      border-top: 1px solid $border-color-basic;
    }
  }

  /* End offers mobile view */

  form .form-group {
    .val {
      line-height: 30px !important;
      border-bottom: 2px solid $border-color-basic;
    }
  }

  .tab-pane.active {
    @include flex-container(column, flex-start, center);
    overflow-y: auto;
    overflow-x: hidden;
    min-height: calc(100vh - 162px);

    &>* {
      display: block;
      width: 100%;
    }

    router-outlet+* {
      display: block;
    }
  }

  form .form-control.is-invalid,
  form .form-control.is-invalid:focus,
  form .ng-select.ng-select-single.is-invalid .ng-select-container {
    border-width: 0 !important;
    border-bottom-width: 2px !important;
    border-color: $error-input !important;

    &+.val {
      border-bottom-width: 2px !important;
      border-color: $error-input !important;
    }
  }

  form .form-group-wrapper {
    .form-group {
      padding: 0 15px;
    }

    .form-control:not(.searchable),
    .ng-select .ng-select-container {
      border-left: 0 !important;
      border-right: 0 !important;
      border-top: 0 !important;
      border-bottom: 2px solid $border-color-basic !important;
      height: 32px !important;
      line-height: 30px !important;
      padding-left: 0 !important;

      &:focus {
        border-bottom-color: $focused-input !important;
        background: transparent;
        border-top-color: transparent !important;
        box-shadow: none;
        -webkit-appearance: none !important;
        border-radius: 0;

        &+.val {
          border-color: $focused-input;
        }
      }
    }
  }

  .cdk-overlay-container .mat-calendar-header .mat-calendar-period-button {
    font-size: 14px;
  }

  app-account-cheques {
    app-icon-card {
      display: none;
    }

    table td span,
    table td a {
      text-overflow: unset;
    }
  }

  app-content-pages ul.ul-shop {
    @include flex-container(row, space-between, center);
  }

  .bottom-contacts {
    .conacts-wrapper {
      @include flex-container(column, center, flex-start);

      .phone {
        margin-bottom: 16px;
        background-position: left 6px center;
      }
    }
  }

  .ng-select {
    background: $ng-select-bg;

    &.ng-select-multiple {
      .ng-arrow-wrapper {
        .ng-arrow {
          top: 0;
        }
      }
    }

    &.ng-select-opened {
      >.ng-select-container {
        .ng-arrow {
          top: 0;
        }
      }
    }
  }

  .point-content.tab-2 {
    padding: 0 16px;
  }

  ng-dropdown-panel.ng-dropdown-panel {
    .ng-dropdown-panel-items {
      max-height: 280px;
    }

    .ng-dropdown-header {
      border-bottom: 0;
    }
  }

  app-store-filter {
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
      top: 13px;
      position: absolute !important;
    }

    .ng-select .ng-select-container .ng-value-container .ng-value {
      position: static;
    }

    .ng-select.ng-select-multiple .ng-select-container {
      overflow-y: auto;
    }

    .ng-dropdown-panel {
      background: $white;
      width: 290px;
      top: 100% !important;
      bottom: auto;
    }
  }

  .body-overflow-mobile {
    overflow: hidden;
    height: 100vh;
    width: 100%;
    position: fixed;
  }

  .modal-header .close {
    right: 32px;
  }

  .tax-details-wrapper {
    .skeleton-wrapper {
      margin: 0 !important;
    }
  }

  .info-skeleton-wrapper {
    padding-left: 0 !important;
  }

  .skeleton-wrapper {
    flex-wrap: wrap;
    margin: 0;

    .half-mobile-wrapper {
      margin: 0 -5px;
      flex: 1 0 auto;
      display: flex;
      flex-wrap: wrap;
    }

    .item-load {
      padding: 0;
      width: 100%;

      &.half-mobile {
        width: 50%;
        padding: 0 6px;
        flex: 0 0 50%;

        .loader {
          height: 70px !important;
        }
      }
    }
  }

  .card-item {
    height: 71px;
  }

  app-qrcode-modal {
    .modal-header {
      padding: 24px 16px;
    }
  }

  .customer-settings {
    border-bottom: 1px solid transparent !important;
  }

  .modal-body-inner {
    padding: 0 10px;
  }

  .page-title {
    display: none;
  }

  app-account-cheques-period-filter {
    width: 55%;
  }

  app-account-cheques-status-filter {
    width: 45%;
  }

  app-account-cheques-period-filter,
  app-account-cheques-status-filter {
    display: block;

    .filter-wrapper {
      display: block;

      form {
        width: 100%;
      }

      .form-group .ng-select {
        width: 100%;
      }
    }
  }

  .download-btn {
    width: 100%;

    .btn.btn-download {
      font-size: 12px;
      letter-spacing: .4px;
      line-height: 14px;
      text-align: left;
      width: 100%;
      padding: 10px 5px 10px 25px;
      background-position: 9px 15px;
    }
  }

  .contact-details-items-wrapper {
    .form-group {
      margin-bottom: 16px;
    }

    .controls-field {
      justify-content: flex-end;
    }
  }

  .page-title-wrapper {
    display: none;

    +.col {
      padding-top: 30px;
    }
  }

  body .form-group .input-group>.form-control:not(:first-child),
  body .form-group .input-group>.custom-select:not(:first-child),
  body .form-group .input-group>.form-control:not(:last-child),
  body .form-group .input-group>.custom-select:not(:last-child) {
    border-radius: 0 !important;
  }

  .input-group {

    .form-control,
    .ng-select {
      .ng-arrow-wrapper {
        @include flex-container(row, center, center);
        width: 30px;
        height: 100%;
        padding-right: 0;
        background: $ng-select-wrapper;
        margin-right: -8px;

        .ng-arrow {
          margin-top: 10px;
        }
      }

      &.ng-select-opened .ng-select-container {
        background: transparent;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &.ng-select-focused {
        &:not(.ng-select-opened)>.ng-select-container {
          box-shadow: none;
        }

        .ng-select-container {
          background: transparent;
          border-bottom-color: $focused-input !important;
        }
      }

      .ng-select-container {
        border: 0 !important;
        border-bottom: 2px solid $border-color-basic !important;
        height: 32px !important;
        line-height: 32px;
        padding-left: 0;
        border-radius: 0 !important;
        font-size: 14px;
        @include flex-container(row, flex-start, flex-end);
        @include trans();

        .ng-value-container {
          padding-left: 0;

          .ng-value {
            position: absolute;
            left: 0;
            top: 10px !important;
          }

          .ng-input {
            top: 0 !important;
          }
        }

        &:focus {
          background: transparent;
          border-bottom-color: $focused-input !important;
        }
      }
    }

    .form-control::placeholder {
      font-size: 14px !important;
    }
  }

  .filter-wrapper {
    margin-top: 24px;

    .ng-select .ng-select-container .ng-value-container .ng-value {
      left: 16px;
      top: 50% !important;
    }
  }

  .add-profile {
    max-width: none;
    width: 100%;
  }

  .modal .modal-dialog {
    width: 100%;
  }

  app-qrcode-modal {
    .modal-body {
      padding: 0 16px 16px;
      max-width: none;
      margin-bottom: 0;

      qrcode {
        canvas {
          display: block;
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }

  .table-wrapper {
    padding: 0;
    margin-bottom: 0;
    background: transparent;
    box-shadow: none;

    .table.table-striped {
      background: transparent;

      thead {
        display: none;
      }

      tbody {
        tr {
          @include flex-container(column, flex-start, flex-start);
          box-shadow: $basic-box-shadow;

          &:nth-of-type(odd) {
            background: $background-light !important;
          }

          &+tr {
            margin-top: 14px;
          }
        }

        td {
          @include flex-container(row, space-between, center);

          padding: 0 16px;
          height: 52px;
          width: 100%;

          .label-big-mobile {
            font-size: 16px;
            line-height: 26px;
            text-align: right;
          }

          .user-date {
            color: $text-secondary;
          }

          span {
            &.product-name {
              max-width: 200px;
              word-break: break-word;
              white-space: pre-wrap;
            }
          }

          a.product-name {
            font-size: 16px;
            max-width: 260px;
            width: 100%;
            padding-right: 15px;

            &:before {
              content: '';
              position: absolute;
            }
          }

          &[ data-label='Place'] {
            justify-content: space-between;
            position: relative;

            &:hover,
            &:focus {
              background: $focused-input-bg;
            }
          }

          &:first-child {
            padding: 0 16px !important;
          }

          &+td {
            border-top: 1px solid $border-color-basic !important;
          }
        }
      }
    }
  }

  .mobile-label {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: $text-secondary;
  }

  .tab-content {
    padding-top: 0;
  }

  app-account-partner {
    app-edit-offer-modal {
      .modal-header {
        padding: 0 0 24px 0;

        h5 {
          position: relative;
          text-align: center;
          width: 100%;
        }

        &:before {
          content: '';
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: $partner-offer-detail-bg;
        }
      }

      .offer-input-group {
        flex-wrap: wrap;

        .form-group {
          width: 100%;

          &.m-left-32 {
            margin-left: 0 !important;

            &+.form-group {
              .input-group {
                flex-wrap: wrap;
              }
            }
          }
        }

        .input-group {
          flex-wrap: nowrap;
        }
      }

      .modal-footer {
        border-top: none;
        padding-top: 0;
        flex-direction: column-reverse;

        > :not(:last-child) {
          margin: 0;
        }

        > :not(:first-child) {
          margin: 0 0 5px 0;
        }

        .btn {
          width: 100%;
        }
      }

      .form-control+mat-datepicker-toggle {
        margin-left: -100%;
        width: 100%;
        height: 32px;
      }
    }

    &>.container {
      padding-top: 24px;
    }

    ngb-tabset {
      padding-top: 0 !important;
    }
  }

  .nav {
    display: block;
    .nav-item .nav-link span:after {
      display: none !important;
    }
    @include flex-container(row, flex-start, center);
    box-shadow: $basic-box-shadow;
    border-radius: 2px;
    background: $background-light;
    border: none;
    margin-top: 24px;

    .nav-item {
      display: block;
      width: 100%;
      position: relative;

      &+.nav-item {
        border-top: 1px solid $border-color-basic;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -6px;
        width: 8px;
        height: 12px;
        background: url("/assets/icons/arrow-right.svg") no-repeat center;
      }

      .nav-link {
        font-size: 16px;
        color: $text-def;
        opacity: 1;
        position: static;

        span {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: 1;
          padding-left: 15px;
          opacity: 1 !important;

          &.active:after,
          &:hover:after {
            display: none;
          }
        }
      }
    }
  }

  .container {
    &.hide {
      .nav {
        display: none;
      }
    }

    &.show {
      .tab-content {
        display: none;
      }
    }
  }

  .submit-controls {
    margin: 24px -15px 0;
    border: 0;

    .btn {
      width: 100%;
    }

    .info-field-info {
      padding: 0 16px;
    }
  }

  label {
    margin-bottom: 0;
    @include trans();

    &.focused {
      color: $link-color;
    }
  }

  app-account-statistics {
    .ng-select .ng-select-container .ng-value-container {
      .ng-value {
        top: 15px;
        left: 10px;
      }

      .ng-input {
        padding-left: 0;
      }
    }
  }

  .form-group-wrapper {
    background: $background-light;
    box-shadow: $basic-box-shadow;
    border-radius: 2px;
    padding: 32px 16px 16px;

    &+.form-group-wrapper {
      margin-top: 10px;
    }
  }

  input::placeholder {
    font-size: 12px !important;
  }

  pagination-controls .ngx-pagination {
    li {
      // width: auto;

      &.pagination-previous {
        margin-left: 0;
        margin-right: 8px;
        flex: 2 0 auto;
      }

      &.pagination-next {
        margin-right: 0;
        flex: 2 0 auto;
        margin-left: 8px
      }
    }
  }

  /* profile list admin */
  .profile-list-wrapper {
    @include flex-container(column, flex-start, center);
    box-shadow: $basic-box-shadow;
    border-radius: 2px;
    background: $background-light;
    border: none;

    .content-wrapper {
      display: flex;
      width: 100%;
      position: relative;
      padding: 16px 16px 16px 20px;

      &+.content-wrapper {
        border-top: 1px solid $border-color-basic;
      }

      &.locked-row {

        .profile-user-email,
        .icon {
          opacity: .4;
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -6px;
        width: 8px;
        height: 12px;
        background: url("/assets/icons/arrow-right.svg") no-repeat center;
      }

      .profile-user-email {
        font-size: 16px;
        color: $profile-user-email;
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon {
        width: 16px;
        height: 19px;
      }
    }
  }

  app-admin-profile-list {
    app-add-edit-profile-modal {
      .modal-footer {
        border-top: none;
        flex-direction: column-reverse;

        &> :not(:first-child) {
          margin: 0 0 4px 0;
        }

        &> :not(:last-child) {
          margin: 0;
        }

        button {
          width: 100%;
        }
      }

      .modal-header {
        position: relative;
        z-index: 1;
      }

      .modal-body {
        position: static;

        &:before {
          content: '';
          background: $edit-profile-bg;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  .offers-select-wrapper .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    position: static;
    display: inline;
  }

  .modal {
    &.delete-offer {
      .modal-header {
        padding: 32px 0 16px;
      }

      .modal-body {
        padding-left: 0;
        padding-right: 0;
      }

      .modal-body-inner {
        padding: 0;
      }

      .modal-footer {
        flex-direction: column-reverse;

        &> :not(:first-child) {
          margin: 0 0 5px 0;
        }

        &> :not(:last-child) {
          margin: 0;
        }

        .btn {
          max-width: none;
        }
      }
    }

    &.new-offer {
      align-items: flex-start;
    }
  }


  app-download-exported-products-modal {
    .modal-header {
      padding: 32px 0 16px;
    }

    .modal-body {
      padding-left: 0;
      padding-right: 0;
    }

    .modal-body-inner {
      padding: 0;
    }

    .form-group {
      padding: 0 !important;
    }

    .form-control {
      width: 100px !important;

      &+mat-datepicker-toggle {
        height: 32px;
      }
    }
  }

  /* profile list admin end */
  app-partner-offer-item {
    position: relative;

    .place-logo {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }
  }

  .mat-datepicker-popup .mat-datepicker-content .mat-calendar {
    width: 300px;
  }

  //.mat-datepicker-popup
  //.mat-datepicker-content
  //.mat-calendar-body-cell-content {
  //  left: 5%;
  //}

  .dropdown-menu {
    margin-top: 10px;

    &.show {
      z-index: 999;
    }
  }

  app-tax-calculator {

    .btn.disabled,
    .btn[ disabled] {
      .icon svg path {
        fill: $white;
      }
    }

    .btn.reverse:not(:disabled),
    .icon svg path {
      fill: $btn-primary;
    }
  }

  /* checkpoints */

  app-checkpoints {
    .tabs-titles {
      display: flex;
      background: $tabs-titles-bg;
    }

    .tab-title {
      color: $custom-tab-title;
      font-size: 16px;
      text-align: center;
      padding: 15px 0;
      width: 50%;
      border-bottom: 1px solid $custom-tab-title;
      opacity: .5;

      &.active {
        opacity: 1;
        border-bottom: 2px solid $custom-tab-title;
      }
    }

    .point-content.tab-2 {
      padding: 40px 0;
      background: $white;
      min-height: calc(100vh - 124px);
      overflow-y: auto;
      @include flex-container(column, flex-start, flex-start);
    }

    .points-wrapper {
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;
    }

    .map-holder,
    agm-map {
      height: calc(100vh - 115px) !important;
    }

    .modal-title {
      margin-left: 24px;
      margin-right: 24px;
      border-bottom: 1px solid $border-color-basic;
    }

    .description {
      font-size: 16px;
      line-height: 26px;
      border-radius: 6px;
      background: $checkpoints-bg;
      margin: 0 16px;
      padding: 24px 16px;
    }

    .tabs-body {

      .nav,
      .tab-content {
        display: none;
      }

      &.show-title {
        .nav {
          margin: 0 16px;
          display: block;
        }
      }

      &.show-content {
        .tab-content {
          display: block;
        }
      }
    }
  }

  app-checkpoints-mobile {
    agm-map {
      height: calc(100vh - 64px) !important;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }

  /* checkpoints end */
  /* notifications */
  app-notifications {
    .container {
      padding-top: 24px;
    }
  }

  /* notifications end */
  .main-info {
    .main-info-text {
      .name {
        &.mobile {
          display: block;

          &:hover {
            cursor: default;
          }
        }

        &.desktop {
          display: none;
        }

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .modal-open .modal.new-offer {
    .modal-dialog {
      .modal-content {
        padding: 0;

        //position: fixed;
        //left: 0;
        //right: 0;
        //top: 0;
        //bottom: 0;
        .form-group-wrapper {
          .offer-input-group {
            flex-direction: column;

            .form-group-wrapper-items {
              flex-direction: column;

              .form-group {
                width: 100%;

                .input-group {
                  flex-wrap: nowrap;

                  .val {
                    padding: 0 !important;

                    &.separate {
                      padding: 0 10px !important;
                    }
                  }
                }
              }
            }
          }
        }

        .modal-body {
          overflow-x: hidden;
        }

        .modal-header {
          padding-top: 50px;
        }

        .modal-footer {
          justify-content: center;
          align-items: center;
          flex-direction: column-reverse;

          .btn {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            max-width: none;
          }
        }
      }
    }
  }
}
